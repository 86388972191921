const NavRoutes = {
  DashboardOverview: { path: "/dashboard/admin" },
  // TwoFAEmail: { path: "/twofa-email" },
  // OnboardingPage: { path: "/landing-page" },
  // Registration: { path: "/registration" },
  // SignUp: { path: "sign-up" },
  // VerifyEmail: { path: "verify-email" },
  // TwoFactorAuth: { path: "two-factor-auth" },
  // TwoFactorPin: { path: "two-factor-pin" },
  SignInUser: { path: "admin/sign-in" },

  Login: { path: "/auth" },

  SignIn: { path: "sign-in" },
  ForgotPass: { path: "forgot-pass" },
  ResetPass: { path: "reset-pass" },
  OtpVerification: { path: "otp-verification" },
  AccountSetup: { path: "/account-setup" },
  Wallets: { path: "/dashboard" },
  Users: { path: "users" },
  Admin: { path: "admin" },
  Complaint: { path: "compain" },
  Transaction: { path: "transaction" },
  TopUp: { path: "transactionsa" },
  FiatWallet: { path: "history" },
  LoyaltyWallet: { path: "transactions" },
  Account: { path: "/account" },
  SupportHelp: { path: "/support/help" },
  ThriftyWallet: { path: "/redonion" },
  TermsAndConditions: { path: "terms-and-condition" },
  PrivacyPolicy: { path: "privacy-policy" },
  AboutPage: { path: "about" },
  FAQ: { path: "FAQ" },
  AML: { path: "aml" },
};

export default NavRoutes;
